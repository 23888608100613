













































































































































.info {
	width: 720px;
	margin: 0 auto;
	.oper-dv {
		height: 20px;
		text-align: right;
		margin-right: 100px;
		a {
			font-weight: 500;
			&:last-child {
				margin-left: 30px;
			}
		}
	}
	.info-item {
		margin-top: 15px;
		label {
			display: inline-block;
			width: 100px;
			text-align: right;
		}
		.sel-img-dv {
			position: relative;
			.sel-file {
				position: absolute;
				width: 90px;
				height: 30px;
				opacity: 0;
				cursor: pointer;
				z-index: 2;
			}
			.sel-btn {
				position: absolute;
				cursor: pointer;
				z-index: 1;
			}
		}
	}
}

.cropper-content {
	display: flex;
	display: -webkit-flex;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
	.cropper {
		width: 260px;
		height: 260px;
	}
	.show-preview {
		flex: 1;
		-webkit-flex: 1;
		display: flex;
		display: -webkit-flex;
		justify-content: center;
		-webkit-justify-content: center;
		.preview {
			overflow: hidden;
			border-radius: 50%;
			border: 1px solid #cccccc;
			background: #cccccc;
			margin-left: 40px;
		}
	}
}

.cropper-content .show-preview .preview {
	margin-left: 0;
}
.CutImg_box .el-button--primary{background-color: #736357; border-color: #736357;}
