.info {
  width: 720px;
  margin: 0 auto;
}
.info .oper-dv {
  height: 20px;
  text-align: right;
  margin-right: 100px;
}
.info .oper-dv a {
  font-weight: 500;
}
.info .oper-dv a:last-child {
  margin-left: 30px;
}
.info .info-item {
  margin-top: 15px;
}
.info .info-item label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
.info .info-item .sel-img-dv {
  position: relative;
}
.info .info-item .sel-img-dv .sel-file {
  position: absolute;
  width: 90px;
  height: 30px;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}
.info .info-item .sel-img-dv .sel-btn {
  position: absolute;
  cursor: pointer;
  z-index: 1;
}
.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
.cropper-content .cropper {
  width: 260px;
  height: 260px;
}
.cropper-content .show-preview {
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
}
.cropper-content .show-preview .preview {
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #cccccc;
  background: #cccccc;
  margin-left: 40px;
}
.cropper-content .show-preview .preview {
  margin-left: 0;
}
.CutImg_box .el-button--primary {
  background-color: #736357;
  border-color: #736357;
}
